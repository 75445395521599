import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { ApplicationConfig, provideZoneChangeDetection } from '@angular/core';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { provideRouter } from '@angular/router';
import { provideAuth0 } from '@auth0/auth0-angular';
import { environment } from '../environments/environment';
import { routes } from './app.routes';

export const appConfig: ApplicationConfig = {
    providers: [
        provideZoneChangeDetection({ eventCoalescing: true }),
        provideRouter(routes),
        provideAuth0({
            domain: environment.LOGIN_URL,
            clientId: environment.CLIENT_ID,
            authorizationParams: {
                redirect_uri: `${window.location.origin}/${window.location.search}`,
                audience: 'https://bannerflow.com/resources/',
                scope: 'openid profile email offline_access'
            },
            httpInterceptor: {
                allowedList: environment.API_URLS.map(url => `${url}/*`)
            },
            useRefreshTokens: true
        }),
        provideHttpClient(withInterceptorsFromDi()), // HttpClient
        provideAnimationsAsync() // Needed for @bannerflow/ui
    ]
};
