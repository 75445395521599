import { Component } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { HelloWorldResponse } from '@bannerflow/types.backend-template';
import { UIModule } from '@bannerflow/ui';

@Component({
    selector: 'app-root',
    standalone: true,
    imports: [RouterOutlet, UIModule],
    templateUrl: './app.component.html',
    styleUrl: './app.component.scss'
})
export class AppComponent {
    title = 'frontend-template';
    helloWorldReponse: HelloWorldResponse | undefined;
}
